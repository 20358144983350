import 'proxy-polyfill';

import {
  acceptedQuote,
  applicationSubmissionSuccessful,
  assignedLead,
  attachmentAddedSuccessully,
  attachmentErrorOccurred,
  clickedButton,
  completedField,
  completedProfile,
  completedStep,
  createdLead,
  deletedLead,
  errorOccurred,
  initiatedApplicationSubmission,
  leadStatusUpdated,
  loggedIn,
  loggedOut,
  openedApplication,
  paymentFailed,
  paymentSucceeded,
  scheduledACall,
  searchedForAnApplication,
  sentLink,
  setTypewriterOptions,
  startedNewApplication,
  stepErrored,
  unassignedLead,
  updatedAddons,
  userDeemedIneligible,
  viewedQuote,
  viewedScreen,
  viewedStep,
} from './generated';

const GeneratedTypewriterMethods = {
  setTypewriterOptions,
  acceptedQuote,
  applicationSubmissionSuccessful,
  assignedLead,
  attachmentAddedSuccessully,
  attachmentErrorOccurred,
  clickedButton,
  completedField,
  completedProfile,
  completedStep,
  createdLead,
  userDeemedIneligible,
  deletedLead,
  errorOccurred,
  initiatedApplicationSubmission,
  leadStatusUpdated,
  loggedIn,
  loggedOut,
  openedApplication,
  paymentFailed,
  paymentSucceeded,
  scheduledACall,
  searchedForAnApplication,
  sentLink,
  startedNewApplication,
  stepErrored,
  unassignedLead,
  updatedAddons,
  viewedQuote,
  viewedScreen,
  viewedStep,
};

type TypewriterInternalMethodNames = 'setTypewriterOptions';
type TrackingMethodNames = keyof Omit<typeof GeneratedTypewriterMethods, TypewriterInternalMethodNames>;

function isInternalMethod(methodName: string): methodName is TypewriterInternalMethodNames {
  return methodName === 'setTypewriterOptions';
}

export const trackingMethods = new Proxy<typeof GeneratedTypewriterMethods>(GeneratedTypewriterMethods, {
  get(target, method) {
    if (typeof method === 'string' && isInternalMethod(method)) {
      return target[method];
    }

    const trackingMethod = target[method as TrackingMethodNames];

    // props is typed as any here as the props for each of the tracking methods are different
    // using an ES6 Proxy still provides us the type safety when consuming these methods as
    // the typings will be from the target method and not this anonymous function
    return (props: any, opts?: SegmentAnalytics.SegmentOpts, callback?: () => void) => {
      const optionsWithContextOverride = Object.assign({}, opts, { context: { ip: null } });
      trackingMethod(props, optionsWithContextOverride, callback);
    };
  },
});
