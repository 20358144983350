/**
 * This client was automatically generated by Segment Typewriter. ** Do Not Edit **
 */

/**
 * Ajv is a peer dependency for development builds. It's used to apply run-time validation
 * to message payloads before passing them on to the underlying analytics instance.
 *
 * Note that the production bundle does not depend on Ajv.
 *
 * You can install it with: `npm install --save-dev ajv`.
 */
import Ajv from 'ajv';

import * as Segment from './segment';

export interface SelectedProductsItem {
  carrierId?: string;
  coverageAmount?: number;
  isFeatured?: boolean;
  price?: number;
  productId?: string;
}
export interface AcceptedQuote {
  /**
   * Unique identifier for each application
   */
  hashedId: string;
  /**
   * Selected product or add-on after having seen the recommendations.
   */
  selectedProducts: SelectedProductsItem[];
}
export interface ApplicationSubmissionSuccessful {
  /**
   * Unique identifier for each application
   */
  hashedId: string;
  /**
   * Number of insured people in an application
   */
  insuredPersonCount: number;
  /**
   * Instant online result after submitting the application
   */
  outcome: string;
  /**
   * Number of products selected in one application
   */
  productCount: number;

  /**
   * Origin where the application is being applied from
   */
  source?: string;
}
export interface AssignedLead {
  /**
   * User's ID. Each user must have a unique ID.
   */
  assignedToUserId: string;
  /**
   * Unique identifier for each application
   */
  hashedId: string;
  /**
   * Unique identifier for lead. Used in the context of the leads management module, where one lead could be starting several applications. Can be null if the communicationMethod is publicLink. Not null if communicationMethod is privateLink.
   */
  leadId: number;
}
export interface AttachmentAddedSuccessully {
  /**
   * id field as found in attachment table
   */
  attachmentId: string;
  /**
   * The format of the document.
   */
  documentFormat: string;
  /**
   * Unique identifier for each application
   */
  hashedId: string;
}
export interface AttachmentErrorOccurred {
  /**
   * The format of the document.
   */
  documentFormat: string;
  /**
   * Summarizes the error that occured.
   */
  error: string;
  /**
   * Unique identifier for each application
   */
  hashedId: string;
}
export interface ClickedButton {
  /**
   * The name of the button. Each botton name should be unique and descriptive of the action the user intends to achieve by clicking the button. If the button is available in many contexts or screens, the buttonName should include this information as well. For now the name is chosen by the developer, but we should eventually develop an automatic naming convention.
   */
  buttonName: string;
  /**
   * Unique identifier for each application
   */
  hashedId: string | null;
}
export interface CompletedField {
  /**
   * Answer path (defined in back-end).
   */
  fieldId: string;
  /**
   * Unique identifier for each application
   */
  hashedId: string | null;
}
export interface CompletedProfile {
  /**
   * Identifies if the field or step answer(s) have changed since the last time it was completed. True is it has changed. False by default (first completed event will always be False) or if the field or event answer has not changed.
   */
  hasChanged: boolean;
}
export interface CompletedStep {
  /**
   * Identifies if the field or step answer(s) have changed since the last time it was completed. True is it has changed. False by default (first completed event will always be False) or if the field or event answer has not changed.
   */
  hasChanged: boolean;
  /**
   * Unique identifier for each application
   */
  hashedId: string;
  /**
   * Array index of the applicant in a multi-insured flow. Starts at 0. Unique within the application but not unique across applications. For example, for  hashedId A we could have insuredPersonIndex 0 and insuredPersonIndex 1. Then, for hashedId B we could have insuredPersonIndex 0, insuredPersonIndex 1 and insuredPersonIndex 2. The index number should be equivalent to the position of the insured in the database array.
   */
  insuredPersonIndex: number;
  /**
   * ID associated with a step (question). Should be unique within each carrier flow (we have to be able to differentiate between steps). Usually equal to the url.
   */
  stepId: string;
}
export interface CreatedLead {
  /**
   * Unique identifier for lead. Used in the context of the leads management module, where one lead could be starting several applications. Can be null if the communicationMethod is publicLink. Not null if communicationMethod is privateLink.
   */
  leadId: number;
}
export interface DeletedLead {
  /**
   * Unique identifier for lead. Used in the context of the leads management module, where one lead could be starting several applications. Can be null if the communicationMethod is publicLink. Not null if communicationMethod is privateLink.
   */
  leadId: number;
}
export interface ErrorOccurred {
  /**
   * Summarizes the error that occured.
   */
  error: string;
  /**
   * Unique identifier for each application
   */
  hashedId: string | null;
}
export interface InitiatedApplicationSubmission {
  /**
   * Unique identifier for each application
   */
  hashedId: string;
}
export interface LeadStatusUpdated {
  /**
   * Unique identifier for each application
   */
  hashedId: string;
  /**
   * Unique identifier for lead. Used in the context of the leads management module, where one lead could be starting several applications. Can be null if the communicationMethod is publicLink. Not null if communicationMethod is privateLink.
   */
  leadId: number;
  /**
   * lead status
   */
  leadStatus: string;
}
export interface OpenedApplication {
  /**
   * Unique identifier for each application
   */
  hashedId: string;
}
export interface PaymentFailed {
  /**
   * Unique identifier for each application
   */
  hashedId: string;
}
export interface PaymentSucceeded {
  /**
   * Unique identifier for each application
   */
  hashedId: string;
}
export interface ScheduledACall {
  /**
   * Unique identifier for each application
   */
  hashedId: string;
  /**
   * ID associated with a step (question). Should be unique within each carrier flow (we have to be able to differentiate between steps). Usually equal to the url.
   */
  stepId: string;
}
export interface SearchedForAnApplication {
  /**
   * Identifies if there is a search result or not.
   */
  hasSearchResults: boolean;
}
export interface SentLink {
  /**
   * Unique identifier for lead. Used in the context of the leads management module, where one lead could be starting several applications. Can be null if the communicationMethod is publicLink. Not null if communicationMethod is privateLink.
   */
  leadId: number;
}
export interface StartedNewApplication {
  /**
   * How the user landed on the application.
   */
  communicationMethod: string;
  /**
   * Unique identifier for each application
   */
  hashedId: string;
  /**
	 * Unique identifier for each application (used when a flow includes a needs analysis module
and a transaction module). This ID should be not null when the event is triggered
in the transaction flow.

	 */
  hashedNeedsAnalysisId: string;
  /**
   * Unique identifier for lead. Used in the context of the leads management module, where one lead could be starting several applications. Can be null if the communicationMethod is publicLink. Not null if communicationMethod is privateLink.
   */
  leadId: number | null;
  /**
   * The name of the module.
   */
  moduleName: string;
}
export interface StepErrored {
  /**
   * Summarizes the error that occured.
   */
  error: string;
  /**
   * Unique identifier for each application
   */
  hashedId: string;
  /**
   * ID associated with a step (question). Should be unique within each carrier flow (we have to be able to differentiate between steps). Usually equal to the url.
   */
  stepId: string;
}
export interface UnassignedLead {
  /**
   * Unique identifier for each application
   */
  hashedId: string;
  /**
   * Unique identifier for lead. Used in the context of the leads management module, where one lead could be starting several applications. Can be null if the communicationMethod is publicLink. Not null if communicationMethod is privateLink.
   */
  leadId: number;
}
export interface UpdatedAddons {
  /**
   * ID of the add-on
   */
  addOnId: string;
  /**
   * Unique identifier for each application
   */
  hashedId: string;
  /**
   * ID associated with a step (question). Should be unique within each carrier flow (we have to be able to differentiate between steps). Usually equal to the url.
   */
  stepId: string;
}
export interface UserDeemedIneligible {
  /**
   * Unique identifier for each application
   */
  hashedId: string;
}
export interface RecommendedProductsItem {
  carrierId?: string;
  coverageAmount?: number;
  isFeatured?: boolean;
  price?: number | null;
  productId?: string;
}
export interface ViewedQuote {
  /**
   * Unique identifier for each application
   */
  hashedId: string;
  /**
   * Recommended product or add-on in the recommendations.
   */
  recommendedProducts: RecommendedProductsItem[];
}
export interface ViewedScreen {
  /**
   * Unique identifier for each application
   */
  hashedId: string | null;
  /**
   * The name of the screen
   */
  screenName: string;
}
export interface ViewedStep {
  /**
   * Unique identifier for each application
   */
  hashedId: string;
  /**
   * ID associated with a step (question). Should be unique within each carrier flow (we have to be able to differentiate between steps). Usually equal to the url.
   */
  stepId: string;
}

export type ViolationHandler = (message: Record<string, any>, violations: Ajv.ErrorObject[]) => void;

/**
 * The default handler that is fired if none is supplied with setTypewriterOptions.
 * This handler will log a warning message to the console.
 */
export const defaultValidationErrorHandler: ViolationHandler = (message, violations) => {
  const msg = JSON.stringify(
    {
      type: 'Typewriter JSON Schema Validation Error',
      description:
        `You made an analytics call (${message.event}) using Typewriter that doesn't match the ` +
        'Tracking Plan spec.',
      errors: violations,
    },
    undefined,
    2
  );

  console.warn(msg);
};

let onViolation = defaultValidationErrorHandler;

let analytics: () => SegmentAnalytics.AnalyticsJS | undefined = () => {
  return window.analytics;
};

/** Options to customize the runtime behavior of a Typewriter client. */
export interface TypewriterOptions {
  /**
   * Underlying analytics instance where analytics calls are forwarded on to.
   * Defaults to window.analytics.
   */
  analytics?: SegmentAnalytics.AnalyticsJS;
  /**
   * Handler fired when if an event does not match its spec. This handler
   * does not fire in production mode, because it requires inlining the full
   * JSON Schema spec for each event in your Tracking Plan.
   *
   * By default, it will throw errors if NODE_ENV = "test" so that tests will fail
   * if a message does not match the spec. Otherwise, errors will be logged to stderr.
   */
  onViolation?: ViolationHandler;
}

/**
 * Updates the run-time configuration of this Typewriter client.
 *
 * @param {TypewriterOptions} options - the options to upsert
 *
 * @typedef {Object} TypewriterOptions
 * @property {Segment.AnalyticsJS} [analytics] - Underlying analytics instance where analytics
 * 		calls are forwarded on to. Defaults to window.analytics.
 * @property {Function} [onViolation] - Handler fired when if an event does not match its spec. This handler does not fire in
 * 		production mode, because it requires inlining the full JSON Schema spec for each event in your Tracking Plan. By default,
 * 		it will throw errors if NODE_ENV="test" so that tests will fail if a message does not match the spec. Otherwise, errors
 * 		will be logged to stderr.
 */
export function setTypewriterOptions(options: TypewriterOptions) {
  analytics = options.analytics ? () => options.analytics || window.analytics : analytics;
  onViolation = options.onViolation || onViolation;
}

/**
 * Validates a message against a JSON Schema using Ajv. If the message
 * is invalid, the `onViolation` handler will be called.
 */
function validateAgainstSchema(message: Record<string, any>, schema: object) {
  const ajv = new Ajv({ schemaId: 'auto', allErrors: true, verbose: true });
  ajv.addMetaSchema(require('ajv/lib/refs/json-schema-draft-06.json'));
  ajv.addMetaSchema(require('ajv/lib/refs/json-schema-draft-04.json'));

  if (!ajv.validate(schema, message) && ajv.errors) {
    onViolation(message, ajv.errors);
  }
}

/**
 * Helper to attach metadata on Typewriter to outbound requests.
 * This is used for attribution and debugging by the Segment team.
 */
function withTypewriterContext(message: Segment.Options = {}): Segment.Options {
  return {
    ...message,
    context: {
      ...(message.context || {}),
      typewriter: {
        language: 'typescript',
        version: '7.4.1',
      },
    },
  };
}

/**
 * @typedef SelectedProductsItem
 * @property {string} [carrierId] -
 * @property {number} [coverageAmount] -
 * @property {boolean} [isFeatured] -
 * @property {number} [price] -
 * @property {string} [productId] -
 */
/**
 * @typedef AcceptedQuote
 * @property {string} hashedId - Unique identifier for each application
 * @property {SelectedProductsItem[]} selectedProducts - Selected product or add-on after having seen the recommendations.
 */
/**
 * @typedef ApplicationSubmissionSuccessful
 * @property {string} hashedId - Unique identifier for each application
 * @property {number} insuredPersonCount - Number of insured people in an application
 * @property {string} outcome - Instant online result after submitting the application
 * @property {number} productCount - Number of products selected in one application
 * @property {string | null} source - Origin where the application is being applied from

/**
 * @typedef AssignedLead
 * @property {string} assignedToUserId - User's ID. Each user must have a unique ID.
 * @property {string} hashedId - Unique identifier for each application
 * @property {number} leadId - Unique identifier for lead. Used in the context of the leads management module, where one lead could be starting several applications. Can be null if the communicationMethod is publicLink. Not null if communicationMethod is privateLink.
 */
/**
 * @typedef AttachmentAddedSuccessully
 * @property {string} attachmentId - id field as found in attachment table
 * @property {string} documentFormat - The format of the document.
 * @property {string} hashedId - Unique identifier for each application
 */
/**
 * @typedef AttachmentErrorOccurred
 * @property {string} documentFormat - The format of the document.
 * @property {string} error - Summarizes the error that occured.
 * @property {string} hashedId - Unique identifier for each application
 */
/**
 * @typedef ClickedButton
 * @property {string} buttonName - The name of the button. Each botton name should be unique and descriptive of the action the user intends to achieve by clicking the button. If the button is available in many contexts or screens, the buttonName should include this information as well. For now the name is chosen by the developer, but we should eventually develop an automatic naming convention.
 * @property {string | null} hashedId - Unique identifier for each application
 */
/**
 * @typedef CompletedField
 * @property {string} fieldId - Answer path (defined in back-end).
 * @property {string | null} hashedId - Unique identifier for each application
 */
/**
 * @typedef CompletedProfile
 * @property {boolean} hasChanged - Identifies if the field or step answer(s) have changed since the last time it was completed. True is it has changed. False by default (first completed event will always be False) or if the field or event answer has not changed.
 */
/**
 * @typedef CompletedStep
 * @property {boolean} hasChanged - Identifies if the field or step answer(s) have changed since the last time it was completed. True is it has changed. False by default (first completed event will always be False) or if the field or event answer has not changed.
 * @property {string} hashedId - Unique identifier for each application
 * @property {number} insuredPersonIndex - Array index of the applicant in a multi-insured flow. Starts at 0. Unique within the application but not unique across applications. For example, for  hashedId A we could have insuredPersonIndex 0 and insuredPersonIndex 1. Then, for hashedId B we could have insuredPersonIndex 0, insuredPersonIndex 1 and insuredPersonIndex 2. The index number should be equivalent to the position of the insured in the database array.
 * @property {string} stepId - ID associated with a step (question). Should be unique within each carrier flow (we have to be able to differentiate between steps). Usually equal to the url.
 */
/**
 * @typedef CreatedLead
 * @property {number} leadId - Unique identifier for lead. Used in the context of the leads management module, where one lead could be starting several applications. Can be null if the communicationMethod is publicLink. Not null if communicationMethod is privateLink.
 */
/**
 * @typedef DeletedLead
 * @property {number} leadId - Unique identifier for lead. Used in the context of the leads management module, where one lead could be starting several applications. Can be null if the communicationMethod is publicLink. Not null if communicationMethod is privateLink.
 */
/**
 * @typedef ErrorOccurred
 * @property {string} error - Summarizes the error that occured.
 * @property {string | null} hashedId - Unique identifier for each application
 */
/**
 * @typedef InitiatedApplicationSubmission
 * @property {string} hashedId - Unique identifier for each application
 */
/**
 * @typedef LeadStatusUpdated
 * @property {string} hashedId - Unique identifier for each application
 * @property {number} leadId - Unique identifier for lead. Used in the context of the leads management module, where one lead could be starting several applications. Can be null if the communicationMethod is publicLink. Not null if communicationMethod is privateLink.
 * @property {string} leadStatus - lead status
 */
/**
 * @typedef OpenedApplication
 * @property {string} hashedId - Unique identifier for each application
 */
/**
 * @typedef PaymentFailed
 * @property {string} hashedId - Unique identifier for each application
 */
/**
 * @typedef PaymentSucceeded
 * @property {string} hashedId - Unique identifier for each application
 */
/**
 * @typedef ScheduledACall
 * @property {string} hashedId - Unique identifier for each application
 * @property {string} stepId - ID associated with a step (question). Should be unique within each carrier flow (we have to be able to differentiate between steps). Usually equal to the url.
 */
/**
 * @typedef SearchedForAnApplication
 * @property {boolean} hasSearchResults - Identifies if there is a search result or not.
 */
/**
 * @typedef SentLink
 * @property {number} leadId - Unique identifier for lead. Used in the context of the leads management module, where one lead could be starting several applications. Can be null if the communicationMethod is publicLink. Not null if communicationMethod is privateLink.
 */
/**
 * @typedef StartedNewApplication
 * @property {string} communicationMethod - How the user landed on the application.
 * @property {string} hashedId - Unique identifier for each application
 * @property {string} hashedNeedsAnalysisId - Unique identifier for each application (used when a flow includes a needs analysis module
and a transaction module). This ID should be not null when the event is triggered
in the transaction flow.

 * @property {number | null} leadId - Unique identifier for lead. Used in the context of the leads management module, where one lead could be starting several applications. Can be null if the communicationMethod is publicLink. Not null if communicationMethod is privateLink.
 * @property {string} moduleName - The name of the module.
 */
/**
 * @typedef StepErrored
 * @property {string} error - Summarizes the error that occured.
 * @property {string} hashedId - Unique identifier for each application
 * @property {string} stepId - ID associated with a step (question). Should be unique within each carrier flow (we have to be able to differentiate between steps). Usually equal to the url.
 */
/**
 * @typedef UnassignedLead
 * @property {string} hashedId - Unique identifier for each application
 * @property {number} leadId - Unique identifier for lead. Used in the context of the leads management module, where one lead could be starting several applications. Can be null if the communicationMethod is publicLink. Not null if communicationMethod is privateLink.
 */
/**
 * @typedef UpdatedAddons
 * @property {string} addOnId - ID of the add-on
 * @property {string} hashedId - Unique identifier for each application
 * @property {string} stepId - ID associated with a step (question). Should be unique within each carrier flow (we have to be able to differentiate between steps). Usually equal to the url.
 */
/**
 * @typedef UserDeemedIneligible
 * @property {string} hashedId - Unique identifier for each application
 */
/**
 * @typedef RecommendedProductsItem
 * @property {string} [carrierId] -
 * @property {number} [coverageAmount] -
 * @property {boolean} [isFeatured] -
 * @property {number} [price] -
 * @property {string} [productId] -
 */
/**
 * @typedef ViewedQuote
 * @property {string} hashedId - Unique identifier for each application
 * @property {RecommendedProductsItem[]} recommendedProducts - Recommended product or add-on in the recommendations.
 */
/**
 * @typedef ViewedScreen
 * @property {string | null} hashedId - Unique identifier for each application
 * @property {string} screenName - The name of the screen
 */
/**
 * @typedef ViewedStep
 * @property {string} hashedId - Unique identifier for each application
 * @property {string} stepId - ID associated with a step (question). Should be unique within each carrier flow (we have to be able to differentiate between steps). Usually equal to the url.
 */

/**
 * Visitor has chosen one of the proposed recommendations.
Triggered when the user clicks on the call to action button.

 *
 * @param {AcceptedQuote} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function acceptedQuote(props: AcceptedQuote, options?: Segment.Options, callback?: Segment.Callback): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Visitor has chosen one of the proposed recommendations.\nTriggered when the user clicks on the call to action button.\n',
    properties: {
      context: {},
      properties: {
        properties: {
          hashedId: {
            description: 'Unique identifier for each application',
            name: 'hashedId',
            type: 'string',
          },
          selectedProducts: {
            description: 'Selected product or add-on after having seen the recommendations.',
            items: {
              properties: {
                carrierId: {
                  enum: ['assomption', 'rbc'],
                  type: 'string',
                },
                coverageAmount: {
                  type: 'integer',
                },
                isFeatured: {
                  type: 'boolean',
                },
                price: {
                  type: 'number',
                },
                productId: {
                  enum: ['t10'],
                  type: 'string',
                },
              },
              type: 'object',
            },
            minItems: 1,
            name: 'selectedProducts',
            type: 'array',
          },
        },
        required: ['hashedId', 'selectedProducts'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Accepted quote',
    type: 'object',
  };
  const message = {
    event: 'Accepted quote',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track('Accepted quote', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Visitor submitted successfully the application. Triggered by the back-end.
 *
 * @param {ApplicationSubmissionSuccessful} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function applicationSubmissionSuccessful(
  props: ApplicationSubmissionSuccessful,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Visitor submitted successfully the application. Triggered by the back-end.',
    properties: {
      context: {},
      properties: {
        properties: {
          hashedId: {
            description: 'Unique identifier for each application',
            name: 'hashedId',
            type: 'string',
          },
          insuredPersonCount: {
            default: 1,
            description: 'Number of insured people in an application',
            minimum: 1,
            name: 'insuredPersonCount',
            type: 'integer',
          },
          outcome: {
            description: 'Instant online result after submitting the application',
            enum: ['accepted', 'conditional', 'referred', 'denied'],
            name: 'outcome',
            type: 'string',
          },
          productCount: {
            default: 1,
            description: 'Number of products selected in one application',
            minimum: 1,
            name: 'productCount',
            type: 'integer',
          },
        },
        required: ['hashedId', 'insuredPersonCount', 'outcome', 'productCount'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Application submission successful',
    type: 'object',
  };
  const message = {
    event: 'Application submission successful',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    const source = props.source ? ` - Source: ${props.source}` : '';
    a.track(`Application submission successful${source}`, props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * User assigned a lead.
 *
 * @param {AssignedLead} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function assignedLead(props: AssignedLead, options?: Segment.Options, callback?: Segment.Callback): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'User assigned a lead.',
    properties: {
      context: {},
      properties: {
        properties: {
          assignedToUserId: {
            description: "User's ID. Each user must have a unique ID.",
            name: 'assignedToUserId',
            type: 'string',
          },
          hashedId: {
            description: 'Unique identifier for each application',
            name: 'hashedId',
            type: 'string',
          },
          leadId: {
            default: null,
            description:
              'Unique identifier for lead. Used in the context of the leads management module, where one lead could be starting several applications. Can be null if the communicationMethod is publicLink. Not null if communicationMethod is privateLink.',
            name: 'leadId',
            type: 'number',
          },
        },
        required: ['assignedToUserId', 'hashedId', 'leadId'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Assigned lead',
    type: 'object',
  };
  const message = {
    event: 'Assigned lead',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track('Assigned lead', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Advisor attaches a file to the application. Triggered when the file is successfully attached to the application.
 *
 * @param {AttachmentAddedSuccessully} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function attachmentAddedSuccessully(
  props: AttachmentAddedSuccessully,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Advisor attaches a file to the application. Triggered when the file is successfully attached to the application.',
    properties: {
      context: {},
      properties: {
        properties: {
          attachmentId: {
            description: 'id field as found in attachment table',
            name: 'attachmentId',
            type: 'string',
          },
          documentFormat: {
            description: 'The format of the document.',
            enum: ['pdf', 'doc', 'png'],
            name: 'documentFormat',
            type: 'string',
          },
          hashedId: {
            description: 'Unique identifier for each application',
            name: 'hashedId',
            type: 'string',
          },
        },
        required: ['attachmentId', 'documentFormat', 'hashedId'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Attachment added successully',
    type: 'object',
  };
  const message = {
    event: 'Attachment added successully',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track('Attachment added successully', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Advisor tries to attach a file but gets an error message. Each error should be unique to the error message.
 *
 * @param {AttachmentErrorOccurred} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function attachmentErrorOccurred(
  props: AttachmentErrorOccurred,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Advisor tries to attach a file but gets an error message. Each error should be unique to the error message.',
    properties: {
      context: {},
      properties: {
        properties: {
          documentFormat: {
            description: 'The format of the document.',
            enum: ['pdf', 'doc', 'png'],
            name: 'documentFormat',
            type: 'string',
          },
          error: {
            description: 'Summarizes the error that occured.',
            name: 'error',
            type: 'string',
          },
          hashedId: {
            description: 'Unique identifier for each application',
            name: 'hashedId',
            type: 'string',
          },
        },
        required: ['documentFormat', 'error', 'hashedId'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Attachment error occurred',
    type: 'object',
  };
  const message = {
    event: 'Attachment error occurred',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track('Attachment error occurred', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * User clicked on a button.
 *
 * @param {ClickedButton} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function clickedButton(props: ClickedButton, options?: Segment.Options, callback?: Segment.Callback): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'User clicked on a button.',
    properties: {
      context: {},
      properties: {
        properties: {
          buttonName: {
            description:
              'The name of the button. Each botton name should be unique and descriptive of the action the user intends to achieve by clicking the button. If the button is available in many contexts or screens, the buttonName should include this information as well. For now the name is chosen by the developer, but we should eventually develop an automatic naming convention.',
            enum: [
              'callForAssistance',
              'switchedLanguage',
              'moreInformation',
              'callerIdentification',
              'sharePublicLinkCopyToClipBoard',
              'updateProfileUploadPicture',
              'leadNewProfileCancel',
              'leadEditProfileCancel',
              'leadNewProfileSave',
              'leadEditProfileSave',
              'leadContactedToggle',
              'leadNoteSave',
              'leadListEmptyCreateLead',
              'generateLink',
              'userEditCopyPublicAgentLink',
              'savedAdvisorProfile',
            ],
            name: 'buttonName',
            type: 'string',
          },
          hashedId: {
            description: 'Unique identifier for each application',
            name: 'hashedId',
            type: ['string', 'null'],
          },
        },
        required: ['buttonName', 'hashedId'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Clicked button',
    type: 'object',
  };
  const message = {
    event: 'Clicked button',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track('Clicked button', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Trigger when user completes any field.
If a field is completed by default (no action required from the user) then no event should be triggered (until the user changes the answer).
For radio button questions (where only one option may be selected), the entire question is just one field. So 'Completed field' is triggered everytime an option (radio button) is selected.
For checkbox questions (where many options might be selected), each checkbox is a field. So 'Completed field' is triggered only when the targeted box is selected.
For free-form text questions, each box is a field. So 'Completed field' is triggered the first time the field is completed and subsequently everytime the answer changes.
For drop-down fields (where option(s) are selected from a drop-down list), the whole field is just one field. So 'Completed field' is triggered the first time an option is selected and subsequently when the answer is changed.

 *
 * @param {CompletedField} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function completedField(props: CompletedField, options?: Segment.Options, callback?: Segment.Callback): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      "Trigger when user completes any field.\nIf a field is completed by default (no action required from the user) then no event should be triggered (until the user changes the answer).\nFor radio button questions (where only one option may be selected), the entire question is just one field. So 'Completed field' is triggered everytime an option (radio button) is selected.\nFor checkbox questions (where many options might be selected), each checkbox is a field. So 'Completed field' is triggered only when the targeted box is selected.\nFor free-form text questions, each box is a field. So 'Completed field' is triggered the first time the field is completed and subsequently everytime the answer changes.\nFor drop-down fields (where option(s) are selected from a drop-down list), the whole field is just one field. So 'Completed field' is triggered the first time an option is selected and subsequently when the answer is changed.\n",
    properties: {
      context: {},
      properties: {
        properties: {
          fieldId: {
            description: 'Answer path (defined in back-end).',
            name: 'fieldId',
            type: 'string',
          },
          hashedId: {
            description: 'Unique identifier for each application',
            name: 'hashedId',
            type: ['string', 'null'],
          },
        },
        required: ['fieldId', 'hashedId'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Completed field',
    type: 'object',
  };
  const message = {
    event: 'Completed field',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track('Completed field', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * User completed his/her profile (on-click of save button).
 *
 * @param {CompletedProfile} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function completedProfile(
  props: CompletedProfile,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'User completed his/her profile (on-click of save button).',
    properties: {
      context: {},
      properties: {
        properties: {
          hasChanged: {
            description:
              'Identifies if the field or step answer(s) have changed since the last time it was completed. True is it has changed. False by default (first completed event will always be False) or if the field or event answer has not changed.',
            name: 'hasChanged',
            type: 'boolean',
          },
        },
        required: ['hasChanged'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Completed profile',
    type: 'object',
  };
  const message = {
    event: 'Completed profile',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track('Completed profile', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Visitor completed any step in the questionnaire. insuredPersonIndex is set to 0 for single-insured flows.
 *
 * @param {CompletedStep} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function completedStep(props: CompletedStep, options?: Segment.Options, callback?: Segment.Callback): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Visitor completed any step in the questionnaire. insuredPersonIndex is set to 0 for single-insured flows.',
    properties: {
      context: {},
      properties: {
        properties: {
          hasChanged: {
            description:
              'Identifies if the field or step answer(s) have changed since the last time it was completed. True is it has changed. False by default (first completed event will always be False) or if the field or event answer has not changed.',
            name: 'hasChanged',
            type: 'boolean',
          },
          hashedId: {
            description: 'Unique identifier for each application',
            name: 'hashedId',
            type: 'string',
          },
          insuredPersonIndex: {
            default: null,
            description:
              'Array index of the applicant in a multi-insured flow. Starts at 0. Unique within the application but not unique across applications. For example, for  hashedId A we could have insuredPersonIndex 0 and insuredPersonIndex 1. Then, for hashedId B we could have insuredPersonIndex 0, insuredPersonIndex 1 and insuredPersonIndex 2. The index number should be equivalent to the position of the insured in the database array.',
            name: 'insuredPersonIndex',
            type: 'integer',
          },
          stepId: {
            description:
              'ID associated with a step (question). Should be unique within each carrier flow (we have to be able to differentiate between steps). Usually equal to the url.',
            name: 'stepId',
            type: 'string',
          },
        },
        required: ['hasChanged', 'hashedId', 'insuredPersonIndex', 'stepId'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Completed step',
    type: 'object',
  };
  const message = {
    event: 'Completed step',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track('Completed step', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * User manually created a new lead.
 *
 * @param {CreatedLead} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function createdLead(props: CreatedLead, options?: Segment.Options, callback?: Segment.Callback): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'User manually created a new lead.',
    properties: {
      context: {},
      properties: {
        properties: {
          leadId: {
            default: null,
            description:
              'Unique identifier for lead. Used in the context of the leads management module, where one lead could be starting several applications. Can be null if the communicationMethod is publicLink. Not null if communicationMethod is privateLink.',
            name: 'leadId',
            type: 'number',
          },
        },
        required: ['leadId'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Created lead',
    type: 'object',
  };
  const message = {
    event: 'Created lead',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track('Created lead', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * User manually deleted a lead.
 *
 * @param {DeletedLead} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function deletedLead(props: DeletedLead, options?: Segment.Options, callback?: Segment.Callback): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'User manually deleted a lead.',
    properties: {
      context: {},
      properties: {
        properties: {
          leadId: {
            default: null,
            description:
              'Unique identifier for lead. Used in the context of the leads management module, where one lead could be starting several applications. Can be null if the communicationMethod is publicLink. Not null if communicationMethod is privateLink.',
            name: 'leadId',
            type: 'number',
          },
        },
        required: ['leadId'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Deleted lead',
    type: 'object',
  };
  const message = {
    event: 'Deleted lead',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track('Deleted lead', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Error occurred in the platform. An error is a UI or UX error, such as incorrect answers to a question.
 *
 * @param {ErrorOccurred} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function errorOccurred(props: ErrorOccurred, options?: Segment.Options, callback?: Segment.Callback): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Error occurred in the platform. An error is a UI or UX error, such as incorrect answers to a question.',
    properties: {
      context: {},
      properties: {
        properties: {
          error: {
            description: 'Summarizes the error that occured.',
            name: 'error',
            type: 'string',
          },
          hashedId: {
            description: 'Unique identifier for each application',
            name: 'hashedId',
            type: ['string', 'null'],
          },
        },
        required: ['error', 'hashedId'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Error occurred',
    type: 'object',
  };
  const message = {
    event: 'Error occurred',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track('Error occurred', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Visitor initiated the process for submitting the application.
 *
 * @param {InitiatedApplicationSubmission} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function initiatedApplicationSubmission(
  props: InitiatedApplicationSubmission,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Visitor initiated the process for submitting the application.',
    properties: {
      context: {},
      properties: {
        properties: {
          hashedId: {
            description: 'Unique identifier for each application',
            name: 'hashedId',
            type: 'string',
          },
        },
        required: ['hashedId'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Initiated application submission',
    type: 'object',
  };
  const message = {
    event: 'Initiated application submission',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track('Initiated application submission', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * The status of the lead is updated.
 *
 * @param {LeadStatusUpdated} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function leadStatusUpdated(
  props: LeadStatusUpdated,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'The status of the lead is updated.',
    properties: {
      context: {},
      properties: {
        properties: {
          hashedId: {
            description: 'Unique identifier for each application',
            name: 'hashedId',
            type: 'string',
          },
          leadId: {
            default: null,
            description:
              'Unique identifier for lead. Used in the context of the leads management module, where one lead could be starting several applications. Can be null if the communicationMethod is publicLink. Not null if communicationMethod is privateLink.',
            name: 'leadId',
            type: 'number',
          },
          leadStatus: {
            description: 'lead status',
            enum: ['new', 'invited', 'planFinderStarted', 'planFinderCompleted', 'engaged'],
            name: 'leadStatus',
            type: 'string',
          },
        },
        required: ['hashedId', 'leadId', 'leadStatus'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Lead status updated',
    type: 'object',
  };
  const message = {
    event: 'Lead status updated',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track('Lead status updated', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * User logged into the platform. The `Identify` segment call should be called as soon as a user logged in.
 *
 * @param {Record<string, any>} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function loggedIn(props: Record<string, any>, options?: Segment.Options, callback?: Segment.Callback): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'User logged into the platform. The `Identify` segment call should be called as soon as a user logged in.',
    properties: {
      context: {},
      properties: {
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Logged in',
    type: 'object',
  };
  const message = {
    event: 'Logged in',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track('Logged in', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * User logged out of the platform.
 *
 * @param {Record<string, any>} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function loggedOut(props: Record<string, any>, options?: Segment.Options, callback?: Segment.Callback): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'User logged out of the platform.',
    properties: {
      context: {},
      properties: {
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Logged out',
    type: 'object',
  };
  const message = {
    event: 'Logged out',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track('Logged out', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * User opened an existing application.
 *
 * @param {OpenedApplication} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function openedApplication(
  props: OpenedApplication,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'User opened an existing application.',
    properties: {
      context: {},
      properties: {
        properties: {
          hashedId: {
            description: 'Unique identifier for each application',
            name: 'hashedId',
            type: 'string',
          },
        },
        required: ['hashedId'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Opened application',
    type: 'object',
  };
  const message = {
    event: 'Opened application',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track('Opened application', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Event triggered when the payment is confirmed as unsuccessful. Happens after the user has completed the payment form and submitted it.
 *
 * @param {PaymentFailed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function paymentFailed(props: PaymentFailed, options?: Segment.Options, callback?: Segment.Callback): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Event triggered when the payment is confirmed as unsuccessful. Happens after the user has completed the payment form and submitted it.',
    properties: {
      context: {},
      properties: {
        properties: {
          hashedId: {
            description: 'Unique identifier for each application',
            name: 'hashedId',
            type: 'string',
          },
        },
        required: ['hashedId'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Payment failed',
    type: 'object',
  };
  const message = {
    event: 'Payment failed',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track('Payment failed', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Event triggered when the payment is confirmed as successful. Happens after the user has completed the payment form and submitted it.
 *
 * @param {PaymentSucceeded} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function paymentSucceeded(
  props: PaymentSucceeded,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Event triggered when the payment is confirmed as successful. Happens after the user has completed the payment form and submitted it.',
    properties: {
      context: {},
      properties: {
        properties: {
          hashedId: {
            description: 'Unique identifier for each application',
            name: 'hashedId',
            type: 'string',
          },
        },
        required: ['hashedId'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Payment succeeded',
    type: 'object',
  };
  const message = {
    event: 'Payment succeeded',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track('Payment succeeded', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Visitor scheduled a call
 *
 * @param {ScheduledACall} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function scheduledACall(props: ScheduledACall, options?: Segment.Options, callback?: Segment.Callback): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Visitor scheduled a call',
    properties: {
      context: {},
      properties: {
        properties: {
          hashedId: {
            description: 'Unique identifier for each application',
            name: 'hashedId',
            type: 'string',
          },
          stepId: {
            description:
              'ID associated with a step (question). Should be unique within each carrier flow (we have to be able to differentiate between steps). Usually equal to the url.',
            name: 'stepId',
            type: 'string',
          },
        },
        required: ['hashedId', 'stepId'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Scheduled a call',
    type: 'object',
  };
  const message = {
    event: 'Scheduled a call',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track('Scheduled a call', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * User searched for an application
 *
 * @param {SearchedForAnApplication} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function searchedForAnApplication(
  props: SearchedForAnApplication,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'User searched for an application',
    properties: {
      context: {},
      properties: {
        properties: {
          hasSearchResults: {
            description: 'Identifies if there is a search result or not.',
            name: 'hasSearchResults',
            type: 'boolean',
          },
        },
        required: ['hasSearchResults'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Searched for an application',
    type: 'object',
  };
  const message = {
    event: 'Searched for an application',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track('Searched for an application', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * User sent a link to the lead by clicking on the `Send` button.
 *
 * @param {SentLink} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function sentLink(props: SentLink, options?: Segment.Options, callback?: Segment.Callback): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'User sent a link to the lead by clicking on the `Send` button.',
    properties: {
      context: {},
      properties: {
        properties: {
          leadId: {
            default: null,
            description:
              'Unique identifier for lead. Used in the context of the leads management module, where one lead could be starting several applications. Can be null if the communicationMethod is publicLink. Not null if communicationMethod is privateLink.',
            name: 'leadId',
            type: 'number',
          },
        },
        required: ['leadId'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Sent link',
    type: 'object',
  };
  const message = {
    event: 'Sent link',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track('Sent link', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * User started a new application or needs analysis. Triggered only once per application.
 *
 * @param {StartedNewApplication} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function startedNewApplication(
  props: StartedNewApplication,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'User started a new application or needs analysis. Triggered only once per application.',
    properties: {
      context: {},
      properties: {
        properties: {
          communicationMethod: {
            description: 'How the user landed on the application.',
            enum: ['privateLink', 'publicLink', 'email', 'notApplicable'],
            name: 'communicationMethod',
            type: 'string',
          },
          hashedId: {
            description: 'Unique identifier for each application',
            name: 'hashedId',
            type: 'string',
          },
          hashedNeedsAnalysisId: {
            default: null,
            description:
              'Unique identifier for each application (used when a flow includes a needs analysis module\nand a transaction module). This ID should be not null when the event is triggered\nin the transaction flow.\n',
            name: 'hashedNeedsAnalysisId',
            type: 'string',
          },
          leadId: {
            default: null,
            description:
              'Unique identifier for lead. Used in the context of the leads management module, where one lead could be starting several applications. Can be null if the communicationMethod is publicLink. Not null if communicationMethod is privateLink.',
            name: 'leadId',
            type: ['number', 'null'],
          },
          moduleName: {
            description: 'The name of the module.',
            enum: ['needsAnalysis', 'application'],
            name: 'moduleName',
            type: 'string',
          },
        },
        required: ['communicationMethod', 'hashedId', 'hashedNeedsAnalysisId', 'leadId', 'moduleName'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Started new application',
    type: 'object',
  };
  const message = {
    event: 'Started new application',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track('Started new application', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Visitor faced with an error when answering any question
 *
 * @param {StepErrored} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function stepErrored(props: StepErrored, options?: Segment.Options, callback?: Segment.Callback): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Visitor faced with an error when answering any question',
    properties: {
      context: {},
      properties: {
        properties: {
          error: {
            description: 'Summarizes the error that occured.',
            name: 'error',
            type: 'string',
          },
          hashedId: {
            description: 'Unique identifier for each application',
            name: 'hashedId',
            type: 'string',
          },
          stepId: {
            description:
              'ID associated with a step (question). Should be unique within each carrier flow (we have to be able to differentiate between steps). Usually equal to the url.',
            name: 'stepId',
            type: 'string',
          },
        },
        required: ['error', 'hashedId', 'stepId'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Step errored',
    type: 'object',
  };
  const message = {
    event: 'Step errored',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track('Step errored', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * User unassigned a lead.
 *
 * @param {UnassignedLead} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function unassignedLead(props: UnassignedLead, options?: Segment.Options, callback?: Segment.Callback): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'User unassigned a lead.',
    properties: {
      context: {},
      properties: {
        properties: {
          hashedId: {
            description: 'Unique identifier for each application',
            name: 'hashedId',
            type: 'string',
          },
          leadId: {
            default: null,
            description:
              'Unique identifier for lead. Used in the context of the leads management module, where one lead could be starting several applications. Can be null if the communicationMethod is publicLink. Not null if communicationMethod is privateLink.',
            name: 'leadId',
            type: 'number',
          },
        },
        required: ['hashedId', 'leadId'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Unassigned lead',
    type: 'object',
  };
  const message = {
    event: 'Unassigned lead',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track('Unassigned lead', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Visitor modified the selection of his/her coverage options.
 *
 * @param {UpdatedAddons} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function updatedAddons(props: UpdatedAddons, options?: Segment.Options, callback?: Segment.Callback): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Visitor modified the selection of his/her coverage options.',
    properties: {
      context: {},
      properties: {
        properties: {
          addOnId: {
            description: 'ID of the add-on',
            name: 'addOnId',
            type: 'string',
          },
          hashedId: {
            description: 'Unique identifier for each application',
            name: 'hashedId',
            type: 'string',
          },
          stepId: {
            description:
              'ID associated with a step (question). Should be unique within each carrier flow (we have to be able to differentiate between steps). Usually equal to the url.',
            name: 'stepId',
            type: 'string',
          },
        },
        required: ['addOnId', 'hashedId', 'stepId'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Updated addons',
    type: 'object',
  };
  const message = {
    event: 'Updated addons',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track('Updated addons', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Applicant answered a question in the application which rendered them ineligible for application.
 *
 * @param {UserDeemedIneligible} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function userDeemedIneligible(
  props: UserDeemedIneligible,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Applicant answered a question in the application which rendered them ineligible for application.',
    properties: {
      context: {},
      properties: {
        properties: {
          hashedId: {
            description: 'Unique identifier for each application',
            name: 'hashedId',
            type: 'string',
          },
        },
        required: ['hashedId'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'User deemed ineligible',
    type: 'object',
  };
  const message = {
    event: 'User deemed ineligible',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track('User deemed ineligible', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Visitor viewed a recommendation.
If the visitor accepts the recommendation and subsequently starts the application flow,
but eventually comes back to the needs analysis and views the quote again, the Viewed Quote triggered
should contain the information/properties that originate from the most recent engine recommendation,
i.e. not from the latest selection by the visitor.

 *
 * @param {ViewedQuote} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function viewedQuote(props: ViewedQuote, options?: Segment.Options, callback?: Segment.Callback): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Visitor viewed a recommendation.\nIf the visitor accepts the recommendation and subsequently starts the application flow,\nbut eventually comes back to the needs analysis and views the quote again, the Viewed Quote triggered\nshould contain the information/properties that originate from the most recent engine recommendation,\ni.e. not from the latest selection by the visitor.\n',
    properties: {
      context: {},
      properties: {
        properties: {
          hashedId: {
            description: 'Unique identifier for each application',
            name: 'hashedId',
            type: 'string',
          },
          recommendedProducts: {
            description: 'Recommended product or add-on in the recommendations.',
            items: {
              properties: {
                carrierId: {
                  enum: ['bnc', 'assomption', 'rbc'],
                  type: 'string',
                },
                coverageAmount: {
                  type: 'integer',
                },
                isFeatured: {
                  type: 'boolean',
                },
                price: {
                  type: 'number',
                },
                productId: {
                  enum: ['t10', 't20'],
                  type: 'string',
                },
              },
              type: 'object',
            },
            minItems: 1,
            name: 'recommendedProducts',
            type: 'array',
          },
        },
        required: ['hashedId', 'recommendedProducts'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Viewed quote',
    type: 'object',
  };
  const message = {
    event: 'Viewed quote',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track('Viewed quote', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * User viewed a pop-up screen. Exp, (scheduleACallForm)
 *
 * @param {ViewedScreen} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function viewedScreen(props: ViewedScreen, options?: Segment.Options, callback?: Segment.Callback): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'User viewed a pop-up screen. Exp, (scheduleACallForm)',
    properties: {
      context: {},
      properties: {
        properties: {
          hashedId: {
            description: 'Unique identifier for each application',
            name: 'hashedId',
            type: ['string', 'null'],
          },
          screenName: {
            description: 'The name of the screen',
            enum: [
              'scheduleACallForm',
              'leadsListActive',
              'leadsListArchived',
              'leadProfile',
              'userProfile',
              'login',
              'logout',
              'leadNeedsAnalysisEmail',
              'leadDelete',
              'sharePublicLink',
              'notificationPreferences',
            ],
            name: 'screenName',
            type: 'string',
          },
        },
        required: ['hashedId', 'screenName'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Viewed screen',
    type: 'object',
  };
  const message = {
    event: 'Viewed screen',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track('Viewed screen', props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * Visitor viewed any step in the questionnaire.
 *
 * @param {ViewedStep} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function viewedStep(props: ViewedStep, options?: Segment.Options, callback?: Segment.Callback): void {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Visitor viewed any step in the questionnaire.',
    properties: {
      context: {},
      properties: {
        properties: {
          hashedId: {
            description: 'Unique identifier for each application',
            name: 'hashedId',
            type: 'string',
          },
          stepId: {
            description:
              'ID associated with a step (question). Should be unique within each carrier flow (we have to be able to differentiate between steps). Usually equal to the url.',
            minLength: 1,
            name: 'stepId',
            type: 'string',
          },
        },
        required: ['hashedId', 'stepId'],
        type: 'object',
      },
      traits: {
        type: 'object',
      },
    },
    required: ['properties'],
    title: 'Viewed step',
    type: 'object',
  };
  const message = {
    event: 'Viewed step',
    properties: props || {},
    options,
  };
  validateAgainstSchema(message, schema);

  const a = analytics();
  if (a) {
    a.track('Viewed step', props || {}, withTypewriterContext(options), callback);
  }
}

const clientAPI = {
  /**
   * Updates the run-time configuration of this Typewriter client.
   *
   * @param {TypewriterOptions} options - the options to upsert
   *
   * @typedef {Object} TypewriterOptions
   * @property {Segment.AnalyticsJS} [analytics] - Underlying analytics instance where analytics
   * 		calls are forwarded on to. Defaults to window.analytics.
   * @property {Function} [onViolation] - Handler fired when if an event does not match its spec. This handler does not fire in
   * 		production mode, because it requires inlining the full JSON Schema spec for each event in your Tracking Plan. By default,
   * 		it will throw errors if NODE_ENV="test" so that tests will fail if a message does not match the spec. Otherwise, errors
   * 		will be logged to stderr.
   */
  setTypewriterOptions,
  /**
	 * Visitor has chosen one of the proposed recommendations.
	Triggered when the user clicks on the call to action button.

	 *
	 * @param {AcceptedQuote} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
  acceptedQuote,
  /**
   * Visitor submitted successfully the application. Triggered by the back-end.
   *
   * @param {ApplicationSubmissionSuccessful} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  applicationSubmissionSuccessful,
  /**
   * User assigned a lead.
   *
   * @param {AssignedLead} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  assignedLead,
  /**
   * Advisor attaches a file to the application. Triggered when the file is successfully attached to the application.
   *
   * @param {AttachmentAddedSuccessully} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  attachmentAddedSuccessully,
  /**
   * Advisor tries to attach a file but gets an error message. Each error should be unique to the error message.
   *
   * @param {AttachmentErrorOccurred} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  attachmentErrorOccurred,
  /**
   * User clicked on a button.
   *
   * @param {ClickedButton} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  clickedButton,
  /**
	 * Trigger when user completes any field.
	If a field is completed by default (no action required from the user) then no event should be triggered (until the user changes the answer).
	For radio button questions (where only one option may be selected), the entire question is just one field. So 'Completed field' is triggered everytime an option (radio button) is selected.
	For checkbox questions (where many options might be selected), each checkbox is a field. So 'Completed field' is triggered only when the targeted box is selected.
	For free-form text questions, each box is a field. So 'Completed field' is triggered the first time the field is completed and subsequently everytime the answer changes.
	For drop-down fields (where option(s) are selected from a drop-down list), the whole field is just one field. So 'Completed field' is triggered the first time an option is selected and subsequently when the answer is changed.

	 *
	 * @param {CompletedField} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
  completedField,
  /**
   * User completed his/her profile (on-click of save button).
   *
   * @param {CompletedProfile} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  completedProfile,
  /**
   * Visitor completed any step in the questionnaire. insuredPersonIndex is set to 0 for single-insured flows.
   *
   * @param {CompletedStep} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  completedStep,
  /**
   * User manually created a new lead.
   *
   * @param {CreatedLead} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  createdLead,
  /**
   * User manually deleted a lead.
   *
   * @param {DeletedLead} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  deletedLead,
  /**
   * Error occurred in the platform. An error is a UI or UX error, such as incorrect answers to a question.
   *
   * @param {ErrorOccurred} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  errorOccurred,
  /**
   * Visitor initiated the process for submitting the application.
   *
   * @param {InitiatedApplicationSubmission} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  initiatedApplicationSubmission,
  /**
   * The status of the lead is updated.
   *
   * @param {LeadStatusUpdated} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  leadStatusUpdated,
  /**
   * User logged into the platform. The `Identify` segment call should be called as soon as a user logged in.
   *
   * @param {Record<string, any>} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  loggedIn,
  /**
   * User logged out of the platform.
   *
   * @param {Record<string, any>} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  loggedOut,
  /**
   * User opened an existing application.
   *
   * @param {OpenedApplication} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  openedApplication,
  /**
   * Event triggered when the payment is confirmed as unsuccessful. Happens after the user has completed the payment form and submitted it.
   *
   * @param {PaymentFailed} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  paymentFailed,
  /**
   * Event triggered when the payment is confirmed as successful. Happens after the user has completed the payment form and submitted it.
   *
   * @param {PaymentSucceeded} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  paymentSucceeded,
  /**
   * Visitor scheduled a call
   *
   * @param {ScheduledACall} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  scheduledACall,
  /**
   * User searched for an application
   *
   * @param {SearchedForAnApplication} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  searchedForAnApplication,
  /**
   * User sent a link to the lead by clicking on the `Send` button.
   *
   * @param {SentLink} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  sentLink,
  /**
   * User started a new application or needs analysis. Triggered only once per application.
   *
   * @param {StartedNewApplication} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  startedNewApplication,
  /**
   * Visitor faced with an error when answering any question
   *
   * @param {StepErrored} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  stepErrored,
  /**
   * User unassigned a lead.
   *
   * @param {UnassignedLead} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  unassignedLead,
  /**
   * Visitor modified the selection of his/her coverage options.
   *
   * @param {UpdatedAddons} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  updatedAddons,
  /**
   * Applicant answered a question in the application which rendered them ineligible for application.
   *
   * @param {UserDeemedIneligible} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  userDeemedIneligible,
  /**
	 * Visitor viewed a recommendation.
	If the visitor accepts the recommendation and subsequently starts the application flow,
	but eventually comes back to the needs analysis and views the quote again, the Viewed Quote triggered
	should contain the information/properties that originate from the most recent engine recommendation,
	i.e. not from the latest selection by the visitor.

	 *
	 * @param {ViewedQuote} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
  viewedQuote,
  /**
   * User viewed a pop-up screen. Exp, (scheduleACallForm)
   *
   * @param {ViewedScreen} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  viewedScreen,
  /**
   * Visitor viewed any step in the questionnaire.
   *
   * @param {ViewedStep} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  viewedStep,
};

export default new Proxy<typeof clientAPI>(clientAPI, {
  get(target, method) {
    if (typeof method === 'string' && target.hasOwnProperty(method)) {
      return target[method as keyof typeof clientAPI];
    }

    return () => {
      console.warn(`⚠️  You made an analytics call (${String(method)}) that can't be found. Either:
    a) Re-generate your typewriter client: \`npx typewriter\`
    b) Add it to your Tracking Plan: https://app.segment.com/breathe-life-core-prod/protocols/tracking-plans/rs_1b6TMDFYJPkJ3IogHbt81qjQ3sK`);
      const a = analytics();
      if (a) {
        a.track(
          'Unknown Analytics Call Fired',
          {
            method,
          },
          withTypewriterContext()
        );
      }
    };
  },
});
