import { TrackingEventsType } from './trackingEvents';

export type TrackingProps = Record<string, unknown>;

export type MetaAnalytics = {
  eventType: 'track' | 'identify';
  eventPayload: {
    event: TrackingEventsType;
    properties: TrackingProps;
    traits?: {
      userType: string;
      properties: TrackingProps;
    };
    context: {
      ip: null; // assigning IP to null will overwrite the value for compliance
    };
  };
};

export type Meta = {
  analytics: MetaAnalytics;
};

export type TrackedAction = { meta: Meta };

export type IdentifyProperties = {
  userId: string;
  traits: {
    userType: string;
    locale: string;
  };
  context?: Record<string, unknown>;
};

export enum ActionTypes {
  analyticsTrack = 'ANALYTICS_TRACK',
  analayticsIdentity = 'ANALYTICS_IDENTIFY',
}

export enum UserType {
  consumer = 'consumer',
  advisor = 'advisor',
}

export enum ButtonName {
  callForAssistance = 'callForAssistance',
  switchedLanguage = 'switchedLanguage',
  moreInformation = 'moreInformation',
  callerIdentification = 'callerIdentification',
  sharePublicLinkCopyToClipBoard = 'sharePublicLinkCopyToClipBoard',
  externalResources = 'externalResources',
  userProfileUploadPicture = 'updateProfileUploadPicture',
  leadNewProfileCancel = 'leadNewProfileCancel',
  leadEditProfileCancel = 'leadEditProfileCancel',
  leadNewProfileSave = 'leadNewProfileSave',
  leadEditProfileSave = 'leadEditProfileSave',
  leadContactedToggle = 'leadContactedToggle',
  leadNoteSave = 'leadNoteSave',
  generateLink = 'generateLink',
  userEditCopyPublicAgentLink = 'userEditCopyPublicAgentLink',
  savedAdvisorProfile = 'savedAdvisorProfile',
}

export enum ScreenName {
  login = 'login',
  logout = 'logout',
  leadsListActive = 'leadsListActive',
  leadsListArchived = 'leadsListArchived',
  leadNeedsAnalysisEmail = 'leadNeedsAnalysisEmail',
  leadProfile = 'leadProfile',
  leadDelete = 'leadDelete',
  notificationPreferences = 'notificationPreferences',
  scheduleACallForm = 'scheduleACallForm',
  sharePublicLink = 'sharePublicLink',
  userProfile = 'userProfile',
}

export enum FieldId {
  userProfileFirstName = 'userProfileFirstName',
  userProfileLastName = 'userProfileLastName',
  userProfilePreferredName = 'userProfilePreferredName',
  userProfileEmail = 'userProfileEmail',
  userProfilePhoneNumber = 'userProfilePhoneNumber',
  userProfileDefaultInsuranceScopes = 'userProfileDefaultInsuranceScopes',
}

export enum ModuleName {
  needsAnalysis = 'needsAnalysis',
  application = 'application',
}
