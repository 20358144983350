// @ts-ignore not able to find redux-segment module
import { EventTypes } from 'redux-segment';

import { GeneralTrackingEvents, TrackingEventsType } from '../trackingEvents';
import { ActionTypes, IdentifyProperties, Meta, MetaAnalytics, TrackingProps } from '../types';

// FIXME: all of these should have standard action creators, and the tracking should be in there.
// See QuoteApplicationRedux for examples
export const TrackerActions = {
  track: (eventName: TrackingEventsType, properties?: TrackingProps) => ({
    type: ActionTypes.analyticsTrack,
    payload: {},
    meta: trackMeta(eventName, properties),
  }),
  identify: (properties: IdentifyProperties) => ({
    type: ActionTypes.analayticsIdentity,
    payload: {},
    meta: identifyTrackMeta(properties),
  }),
  identifyCustomer: (properties: TrackingProps) => ({
    type: ActionTypes.analayticsIdentity,
    payload: {},
    meta: {
      analytics: {
        eventType: EventTypes.identify,
        eventPayload: {
          traits: {
            userType: 'customer',
            ...properties,
          },
          context: {
            ip: null,
          },
        },
      },
    },
  }),
};

export enum ScreenType {
  scheduleCall = 'schedule_a_call_form',
  continueLater = 'continue_later_popup',
  seeDetails = 'see_details',
}

export function viewedScreen(screen: ScreenType) {
  return TrackerActions.track(GeneralTrackingEvents.viewedScreen, {
    screen_name: screen,
  });
}

export function trackMeta(event: TrackingEventsType, props: TrackingProps = {}): Meta {
  return {
    analytics: trackMetaSingle(event, props),
  };
}

export function trackMetaSingle(event: TrackingEventsType, props: TrackingProps = {}): MetaAnalytics {
  return {
    eventType: EventTypes.track,
    eventPayload: {
      event,
      properties: props || {},
      context: {
        ip: null,
      },
    },
  };
}

export function identifyTrackMeta(props: IdentifyProperties) {
  return {
    analytics: identifyMetaSingle(props),
  };
}

export function identifyMetaSingle(props: IdentifyProperties) {
  return {
    eventType: EventTypes.identify,
    eventPayload: {
      ...props,
      context: {
        ...(props.context || {}),
        ip: null,
      },
    },
  };
}
