export function group(groupId: string, traits?: Record<string, unknown>, callback?: () => void): void {
  const analytics: SegmentAnalytics.AnalyticsJS | undefined = window.analytics;

  if (!analytics) {
    callback?.();
    return;
  }

  analytics.group(
    groupId,
    traits,
    {
      context: {
        ip: null,
      },
    },
    callback
  );
}
