import { useCallback } from 'react';
import { useStore, useDispatch } from 'react-redux';

import { track, Track, TrackingQuestion } from './tracking';
import { UserType } from './types';

export type SelectorReturnType = {
  userType?: UserType;
  applicationId?: string;
  questionnaireVersion?: string;
  question?: TrackingQuestion;
  language?: string;
};

export function useTracking<T>(contextPropertiesSelector: (store: T) => SelectorReturnType): Track {
  const store = useStore<T>();
  const dispatch = useDispatch();

  const { applicationId, questionnaireVersion, question, userType, language } = contextPropertiesSelector(
    store.getState()
  );

  const memoizedTrack = useCallback(
    track(dispatch, applicationId, questionnaireVersion, question, userType, language),
    [applicationId, questionnaireVersion, question, userType, language]
  );

  return memoizedTrack;
}
