// For non-typewriter legacy tracking
export enum GeneralTrackingEvents {
  viewedQuote = 'Viewed quote',
  viewedScreen = 'Viewed screen',

  startedApplication = 'Started new application',
  searchedApplication = 'Searched for an application',
  initiatedSubmission = 'Initiated application submission',
  submissionSuccess = 'Application submission successful',

  pageLoaded = 'Page loaded',
  acceptedQuote = 'Accepted quote',

  viewedStep = 'Viewed step',
  completedStep = 'Completed step',
  completedField = 'Completed field',

  scheduledCall = 'Scheduled a call',
  clickedButton = 'Clicked button',

  updatedAddons = 'Updated addons', // viaction
  paymentSucceeded = 'Payment succeeded',
}

export enum AgentTrackingEvents {
  agentLoggedIn = 'Logged in',
  agentLoggedOut = 'Logged out',
  agentAssignedLead = 'Assigned a lead',
  agentUnassignedLead = 'Unassigned a lead',
  agentUpdatedLeadState = 'Updated lead state',
  agentCompletedProfile = 'Completed profile',
  sentLink = 'Sent link',
  createdLead = 'Created lead',
  deletedLead = 'Deleted lead',
  searchedForApplication = 'Searched for an application',
}

export enum ErrorTrackingEvents {
  stepError = 'Step errored',
  errorOccurred = 'Error occured',
}

export const stepEvents = [
  GeneralTrackingEvents.completedField,
  GeneralTrackingEvents.completedStep,
  GeneralTrackingEvents.viewedStep,
  ErrorTrackingEvents.stepError,
];

export type TrackingEventsType = GeneralTrackingEvents | AgentTrackingEvents | ErrorTrackingEvents;
