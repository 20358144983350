import _ from 'lodash';
import { Dispatch } from 'redux';

import { hash } from '@breathelife/hash';

import { TrackerActions } from './redux/trackerActions';
import { TrackingEventsType, stepEvents } from './trackingEvents';
import { TrackingProps, UserType } from './types';

export type Track = (eventName: TrackingEventsType, properties?: TrackingProps) => void;
export type TrackingQuestion = {
  id: string;
  section: string;
};

export const track =
  (
    dispatch: Dispatch,
    applicationId = '',
    questionnaireVersion = '',
    question: TrackingQuestion = { id: '', section: '' },
    userType?: UserType,
    language?: string
  ) =>
  (eventName: TrackingEventsType, properties?: TrackingProps) => {
    const isStepEvent = _.includes(stepEvents, eventName);
    let stepProperties = {};
    if (isStepEvent) {
      const stepId = question?.id;
      stepProperties = {
        stepId,
        sectionId: question.section,
        sectionIndex: 0,
      };
    }

    dispatch(
      TrackerActions.track(eventName, {
        hashedId: hash(applicationId),
        questionnaireVersion,
        ...stepProperties,
        ...properties,
        userType,
        language,
      })
    );
  };
